import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { DeptCode } from "./models/dept-code.model";

@Injectable({
  providedIn: "root"
})
export class DeptCodesService {
  private apiUrl: string;
  
  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getDeptCodes() {
    const params: HttpParams = new HttpParams({
      fromObject: {
        MaxResultCount: 50
      }
    });
    return this.http.get<any>(`${this.apiUrl}/app/deptCode`, { params });
  }

  update(code: DeptCode) {
    return this.http.put<any>(`${this.apiUrl}/app/deptCode/${code.id}`, code);
  }

  create(code: DeptCode) {
    return this.http.post<any>(`${this.apiUrl}/app/deptCode`, code);
  }

  remove(codeId: string) {
    return this.http.delete(`${this.apiUrl}/app/deptCode/${codeId}`);
  }
}
