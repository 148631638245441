import { Injectable } from "@angular/core";
import { Action, Actions, Selector, State, StateContext, ofAction } from "@ngxs/store";
import { takeUntil, tap } from "rxjs/operators";
import { FilterAction } from "../../../../shared/components/filters/filters.component";
import { AddFilter, LoadTerminalsAsOptions, SetFilter } from "../../../../shared/states/prefill-data/prefill-data.actions";
import { getFilterData, getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults, AdminPagesStateModel, SetError } from "../../admin.state";
import { Terminal } from "../models/terminal.model";
import { TerminalsService } from "../terminals.service";
import { AddTerminal, LoadTerminals, RemoveTerminal, UpdateTerminal } from "./terminals.actions";

export class TerminalsStateModel extends AdminPagesStateModel {
  items: Terminal[];
}

@State<TerminalsStateModel>({
  name: "terminals",
  defaults: AdminPagesStateDefaults
})
@Injectable()
export class TerminalsState {
  
  isFiltered: false;
  
  constructor(
    private terminalsService: TerminalsService,
    private actions$: Actions
  ) {}

  @Selector() static terminals(state: TerminalsStateModel) { return state.items; }
  @Selector() static loading(state: TerminalsStateModel) { return state.loading; }
  @Selector() static loaded(state: TerminalsStateModel) { return state.loaded; }
  @Selector() static saving(state: TerminalsStateModel) { return state.saving; }

  @Action(LoadTerminals)
  loadTerminals(ctx: StateContext<TerminalsStateModel>, { filters }) {
    ctx.patchState({ items: [], loading: true });

    return this.terminalsService.getTerminals(filters?.items || {}).pipe(
      tap(
        (response) => {
          this.isFiltered = filters?.hasEnabledFilters;

          ctx.patchState({
            items: response.items,
            loaded: true,
            loading: false
          });

          ctx.dispatch(new LoadTerminalsAsOptions());

          if (!this.isFiltered) {
            const portsArray = getFilterData(response, "AvailablePorts");
            ctx.dispatch(new SetFilter("filteredPorts", portsArray));
          }
        },
        (err) => {
          ctx.patchState({ loading: false, items: [] });
          ctx.dispatch(new SetError({ loading: err }));
        }
      ),
      takeUntil(this.actions$.pipe(ofAction(FilterAction)))
    );
  }

  @Action(AddTerminal)
  addTerminal(ctx: StateContext<TerminalsStateModel>, action: AddTerminal) {
    const state = ctx.getState();

    ctx.patchState({ saving: true });
    return this.terminalsService.createTerminal(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
  
          ctx.dispatch(
            new AddFilter("filteredPorts", {
              key: response.port.id,
              value: response.port.name
            })
          );
  
          if (this.isFiltered) {
            ctx.dispatch(new LoadTerminals()).subscribe((res: any) => {
              let terminals: Terminal[] = [...res.terminals.items];
              terminals.pop();
  
              ctx.patchState({
                items: [response, ...terminals],
                saving: false
              });
            });
          } else {
            ctx.patchState({
              items: [response, ...state.items],
              saving: false
            });
          }
        },
        (error) => {
          ctx.patchState({ loading: false, saving: false });
          action.callbackError();
        }
      )
    );
  }

  @Action(UpdateTerminal)
  updateTerminal(ctx: StateContext<TerminalsStateModel>, action: UpdateTerminal) {
    const state = ctx.getState();
    return this.terminalsService.updateTerminal(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          ctx.patchState({
            items: getUpdatedItems(response, state.items),
            saving: false
          });
        },
        (error) => {
          ctx.patchState({ saving: false });
          action.callbackError();
        }
      )
    );
  }

  @Action(RemoveTerminal)
  removeTerminal(ctx: StateContext<TerminalsStateModel>, action: RemoveTerminal) {
    
    return this.terminalsService.removeTerminal(action.terminalId).pipe(
      tap(
        () => {
          action.callbackSuccess();
          const state = ctx.getState();
          const updatedTerminals = state.items.filter(item => {
            return item.id !== action.terminalId;
          });
          ctx.patchState({ items: updatedTerminals });
        },
        (error) => {
          action.callbackError();
        }
      )
    );
  }
}
