import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { API_URL } from "src/environments/settings";
import { Employer } from "./models/employer.model";

@Injectable({ providedIn: "root" })
export class EmployersService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getEmployers() {
    return this.http.get<any>(`${this.apiUrl}/app/employer?MaxResultCount=50`);
  }

  getExportCompanyTypes() {
    return this.http.get<any>(`${this.apiUrl}/app/employer/exportCompanyList`).pipe(
      map((response) => {
        return response.map(item => {
          return { text: item.name, value: item.id };
        });
      })
    );
  }

  update(employer: Employer) {
    return this.http.put<any>(`${this.apiUrl}/app/employer/${employer.id}`, employer);
  }

  create(employer: Employer) {
    return this.http.post<any>(`${this.apiUrl}/app/employer`, employer);
  }

  remove(employerId: number) {
    return this.http.delete(`${this.apiUrl}/app/employer/${employerId}`);
  }

  getWithFtpCredentials(id: number) {
    return this.http.get<any>(`${this.apiUrl}/app/employer/${id}/employerWithFtpCredentials`);
  }
}
