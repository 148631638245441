import { Permission } from "src/app/core/enums/permission.enum";
import { NavigationItem } from "src/app/shared/interfaces/navigation-item.interface";

export const DASHBOARD_TABS: Array<NavigationItem> = [
  {
    text: "Labor Orders",
    routerLink: ["/orders"],
    permissions: [
      Permission.Administrator,
      Permission.UnionDispatcher,
      Permission.EmployerTest,
      Permission.UnionSupervisor
    ]
  },
  {
    text: "Dispatch Orders",
    routerLink: ["/dispatch"],
    permissions: [
      Permission.Administrator,
      Permission.UnionDispatcher,
      Permission.UnionSupervisor
    ]
  },
  {
    text: "Dispatch By Date",
    routerLink: ["/dispatch-by-date"],
    permissions: [
      Permission.Administrator,
      Permission.UnionDispatcher,
      Permission.UnionSupervisor
    ]
  },
  {
    text: "Availability",
    routerLink: ["/availability"],
    permissions: [
      Permission.Administrator,
      Permission.UnionDispatcher,
      Permission.UnionSupervisor
    ]
  },
  {
    text: "Podium",
    routerLink: ["/podium"],
    permissions: [
      Permission.Administrator,
      Permission.UnionDispatcher
    ]
  }
];