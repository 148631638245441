import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { Filters } from "../../../shared/interfaces/filters.interface";
import { Terminal } from "./models/terminal.model";

@Injectable({ providedIn: "root" })
export class TerminalsService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = `${this.env.getValueTransferState(API_URL)}/app/terminal`;
  }

  getTerminals(filters?: Filters) {
    if (this.env.isBrowser) {
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          if (filters[key] === '') {
            delete filters[key];
          }
        }
      }

      const params: HttpParams = new HttpParams({
        fromObject: {
          ...filters,
          MaxResultCount: 50
        }
      });
      return this.http.get<any>(`${this.apiUrl}/withFilters`, { params });
    }
  }

  updateTerminal(terminal: Terminal) {
    return this.http.put<any>(`${this.apiUrl}/${terminal.id}`, terminal);
  }

  createTerminal(terminal: Terminal) {
    return this.http.post<any>(this.apiUrl, terminal);
  }

  removeTerminal(terminalId: string) {
    return this.http.delete(`${this.apiUrl}/${terminalId}`);
  }
}
