import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { Union } from "./models/unions.model";

@Injectable({
  providedIn: "root"
})
export class UnionsService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getUnions() {
    return this.http.get<any>(`${this.apiUrl}/app/union?MaxResultCount=50`);
  }

  getOccCodes(unionId) {
    return this.http.get<any>(`${this.apiUrl}/app/union/${unionId}/occCodes`);
  }

  getOccCodesByUnionIdAndCargoTypeId(unionId, cargoTypeId) {
    return this.http.get<any>(`${this.apiUrl}/app/union/${unionId}/occCodesByCargoType/${cargoTypeId}`);
  }

  getOccCodesByUnionIdAndCargoTypeIdAndEmployerId(unionId, cargoTypeId, employerId) {
    return this.http.get<any>(`${this.apiUrl}/app/union/${unionId}/occCodesByCargoTypeForTimesheet?employerId=${employerId}&masterCargoTypeId=${cargoTypeId}`);
  }

  update(union: Union) {
    return this.http.put<any>(`${this.apiUrl}/app/union/${union.id}`, union);
  }

  create(union: Union) {
    return this.http.post<any>(`${this.apiUrl}/app/union`, union);
  }

  remove(unionId: number) {
    return this.http.delete(`${this.apiUrl}/app/union/${unionId}`);
  }

  getById(unionId: string) {
    return this.http.get<any>(`${this.apiUrl}/app/union/${unionId}`);
  }
}
