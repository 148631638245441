import { NavigationItem } from "../../interfaces/navigation-item.interface";
import { SelectOption } from "../../interfaces/select-option.interface";

export class PrefillDataStateModel {
  public states: SelectOption[];
  public filteredStates: SelectOption[];
  public allPorts: SelectOption[];
  public allTerminals: SelectOption[];
  public allSuspensionTypes: SelectOption[];
  public allDebitTypes: SelectOption[];
  public allEmployers: SelectOption[];
  public deptCodes: SelectOption[];
  public allVessels: SelectOption[];  
  public filteredPorts: SelectOption[];
  public filteredTerminals: SelectOption[];
  public filteredEmployers: SelectOption[];
  public facilities: SelectOption[];
  public occCodes: SelectOption[];
  public statuses: SelectOption[];
  public cities: SelectOption[];
  public terminalsForPort: SelectOption[];
  public loaded: boolean;
  public error: boolean;
  public cargoDifferentials: SelectOption[];
  public jobTypes: SelectOption[];
  public allBerths: SelectOption[];
  public masterCargoTypes: SelectOption[];
  public availabilityTypes: SelectOption[];
  public filteredVessels: SelectOption[];
  public filteredTimekeepers: SelectOption[];
  public tenantId: string;
  public displayDashboard: boolean;
  public systemNotifications: Array<string>;
  public overrideCertifications: boolean;
  public useWorkerQueue: boolean;
  public trainingHoursMaxLimitEnabled: boolean;
  public allUsers: SelectOption[];

  public allUnions: SelectOption[];
  public musterUnions: SelectOption[];
  public dashboardTabs: NavigationItem[];
  public useMuster: boolean;
}