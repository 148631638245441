import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { LoadEmployersAsOptions } from 'src/app/shared/states/prefill-data/prefill-data.actions';
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { EmployersService } from "../employers.service";
import { AddEmployer, LoadEmployers, LoadExportCompanyTypes, RemoveEmployer, UpdateEmployer } from "./employers.actions";
import { EmployersStateModel } from "./employers.model";

@State<EmployersStateModel>({
  name: "employers",
  defaults: {
    ...AdminPagesStateDefaults,
    exportCompanyTypes: []
  }
})
@Injectable()
export class EmployersState {

  constructor(
    private employersService: EmployersService, 
  ) {}

  @Selector() static employers(state: EmployersStateModel) { return state.items; }
  @Selector() static exportCompanyTypes(state: EmployersStateModel) { return state.exportCompanyTypes; }
  @Selector() static loading(state: EmployersStateModel) { return state.loading; }
  @Selector() static loaded(state: EmployersStateModel) { return state.loaded; }
  @Selector() static saving(state: EmployersStateModel) { return state.saving; }

  @Action(LoadEmployers)
  loadEmployers(ctx: StateContext<EmployersStateModel>) {
    ctx.patchState({
      items: [],
      loading: true
    });

    return this.employersService.getEmployers().pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            loaded: true,
            loading: false
          });
          ctx.dispatch(new LoadEmployersAsOptions());
        }
      )
    );  
  }

  @Action(AddEmployer)
  addEmployer(ctx: StateContext<EmployersStateModel>, action: AddEmployer) {
    ctx.patchState({ saving: true });
    
    return this.employersService.create(action.payload).subscribe(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: [response, ...state.items],
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({
          saving: false
        });
      }
    );
  }

  @Action(UpdateEmployer)
  updateEmployer(ctx: StateContext<EmployersStateModel>, action: UpdateEmployer) {
    ctx.patchState({ saving: true });
    
    return this.employersService.update(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: getUpdatedItems(response, state.items),
            saving: false
          });
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(RemoveEmployer)
  removeEmployer(ctx: StateContext<EmployersStateModel>, action: RemoveEmployer) {
    const state = ctx.getState();

    return this.employersService.remove(action.employerId).pipe(
      tap(
        () => {
          action.callbackSuccess();
          const updatedEmployers = state.items.filter(item => {
            return item.id !== action.employerId;
          });
          ctx.patchState({ items: updatedEmployers });
        },
        (error) => {
          action.callbackError();
        }
      )
    );
  }

  @Action(LoadExportCompanyTypes)
  loadExportCompanyTypes(ctx: StateContext<EmployersStateModel>) {
    ctx.patchState({
      exportCompanyTypes: []
    });

    return this.employersService.getExportCompanyTypes().pipe(
      tap(
        (response) => {
          ctx.patchState({
            exportCompanyTypes: response
          });
        }
      )
    );  
  }
}
