import { WorkerAvailability } from "src/app/shared/interfaces/worker-availability.interface";

export class GetWeekAvailability {
  public static readonly type = "[Availability] load week availability";
  constructor(
    public searchQ: string,
    public unionId: number,
    public skipCount: number,
    public maxResults: number
  ) {}
}

export class SaveAvailabilityWorker {
  public static readonly type = "[Availability] save availability for worker";
  constructor(
    public availability: WorkerAvailability,
    public callback: (error) => void
  ) {}
}

export class SaveWorkerAvailabilityWithExpireDate{
  public static readonly type = "[Availability] save availability for worker with expire date";
  constructor(
    public availability: WorkerAvailability,
    public callback: (error) => void
  ) {}
}

export class GetAvailabilityTypes {
  public static readonly type = "[Availability] load availability types";
  constructor() {}
}

export class ResetAvailability {
  public static readonly type = "[Availability] Reset";
  constructor() {}
}


