import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { LoadUnionsAsOptions } from "../../../../shared/states/prefill-data/prefill-data.actions";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { UnionsService } from "../unions.service";
import { AddUnion, LoadUnions, RemoveUnion, UpdateUnion } from "./unions.actions";
import { UnionsStateModel } from "./unions.model";

@State<UnionsStateModel>({
  name: "unions",
  defaults: AdminPagesStateDefaults
})
@Injectable()
export class UnionsState {

  constructor(
    private unionsService: UnionsService
  ) {}

  @Selector() static unions(state: UnionsStateModel) { return state.items; }
  @Selector() static loading(state: UnionsStateModel) { return state.loading; }
  @Selector() static saving(state: UnionsStateModel) { return state.saving; }

  @Action(LoadUnions)
  load(ctx: StateContext<UnionsStateModel>) {
    ctx.patchState({
      items: [],
      loading: true
    });

    return this.unionsService.getUnions().pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            loaded: true,
            loading: false
          });
          ctx.dispatch(new LoadUnionsAsOptions());
        }
      )
    );
  }

  @Action(AddUnion)
  addUnion(ctx: StateContext<UnionsStateModel>, action: AddUnion) {
    ctx.patchState({ saving: true });
    
    return this.unionsService.create(action.payload).subscribe(
      (response) => {
        const state = ctx.getState();
        action.callbackSuccess();
        ctx.patchState({
          items: [response, ...state.items],
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(UpdateUnion)
  updateUnion(ctx: StateContext<UnionsStateModel>, action: UpdateUnion) {
    ctx.patchState({ saving: true });
    
    return this.unionsService.update(action.payload).subscribe(
      (response) => {
        const state = ctx.getState();
        action.callbackSuccess();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(RemoveUnion)
  removeUnion(ctx: StateContext<UnionsStateModel>, action: RemoveUnion) {
    const state = ctx.getState();
    return this.unionsService.remove(action.unionId).subscribe(
      () => {
        action.callbackSuccess();
        const updated = state.items.filter(item => {
          return item.id !== action.unionId;
        });
        ctx.patchState({ items: updated });
      },
      (error) => {
        action.callbackError();
      }
    );
  }
}
