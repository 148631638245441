import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { Berth } from "src/app/features/admin/berths/state/berths.model";
import { BerthsState } from "src/app/features/admin/berths/state/berths.state";
import { DeptCode } from "src/app/features/admin/dept-codes/models/dept-code.model";
import { DeptCodesState } from "src/app/features/admin/dept-codes/state/dept-codes.state";
import { EmployersState } from "src/app/features/admin/employers/state/employers.state";
import { Terminal } from "src/app/features/admin/terminals/models/terminal.model";
import { UnionsState } from "src/app/features/admin/unions/state/unions.state";
import { UsersPermissionsService } from "src/app/features/admin/users-permissions/users-permissions.service";
import { AvailabilityState } from "src/app/features/availability/state/availability.state";
import { DASHBOARD_TABS } from "src/app/shared/consts/dashboard-tabs.const";
import { PortsState } from "../../../features/admin/ports/state/ports.state";
import { TerminalsState } from "../../../features/admin/terminals/state/terminals.state";
import { NavigationItem } from "../../interfaces/navigation-item.interface";
import { SelectOption } from "../../interfaces/select-option.interface";
import { DropdownOptionsService } from "../../services/dropdown-options.service";
import { filterInList } from "../../utils/utils";
import {
  AddCityAsOption,
  AddFilter,
  AddStateAsOption,
  GetTerminalsOptionsByPort,
  LoadAvailabilityTypesAsOptions,
  LoadBerthsAsOptions,
  LoadCitiesAsOptions,
  LoadDebitTypesAsOptions,
  LoadDeptCodesAsOptions,
  LoadEmployersAsOptions,
  LoadJobTypesAsOptions,
  LoadPortsAsOptions,
  LoadPrefillData,
  LoadSuspensionTypesAsOptions,
  LoadTenantId,
  LoadTerminalsAsOptions,
  LoadUnionsAsOptions,
  LoadUseMuster,
  LoadUsersAsOptions,
  LoadVesselsAsOptions,
  RemoveFilter,
  SetFilter,
  SetStatesAsOptions
} from "./prefill-data.actions";
import { PrefillDataStateModel } from "./prefill-data.model";

@State<PrefillDataStateModel>({
  name: "prefillData",
  defaults: {
    states: [],
    filteredStates: [],
    facilities: [],
    occCodes: [],
    statuses: [],
    cities: [],
    allPorts: [],
    allTerminals: [],
    allEmployers: [],
    deptCodes: [],
    allSuspensionTypes: [],
    allDebitTypes: [],
    allVessels: [],
    allBerths: [],
    filteredPorts: [],
    filteredTerminals: [],
    filteredEmployers: [],
    terminalsForPort: [],
    loaded: false,
    error: false,
    cargoDifferentials: [],
    jobTypes: [],
    masterCargoTypes: [],
    availabilityTypes: [],
    filteredTimekeepers: [],
    filteredVessels: [],
    tenantId: "",
    displayDashboard: false,
    systemNotifications: [],
    overrideCertifications: false,
    useWorkerQueue: false,
    trainingHoursMaxLimitEnabled: false,
    allUsers: [],

    allUnions: [],
    musterUnions: [],
    dashboardTabs: [],
    useMuster: false
  }
})
@Injectable()
export class PrefillDataState {
  constructor(
    private dropdownOptionsService: DropdownOptionsService,
    private userPermissionsService: UsersPermissionsService,
    private store: Store
  ) {}

  @Selector() static loaded(state: PrefillDataStateModel) { return state.loaded; }
  @Selector() static error(state: PrefillDataStateModel) { return state.error; }
  
  @Selector() static states(state: PrefillDataStateModel) { return state.states; }
  @Selector() static facilities(state: PrefillDataStateModel) { return state.facilities; }
  @Selector() static deptCodes(state: PrefillDataStateModel) { return state.deptCodes; }
  @Selector() static masterCargoTypes(state: PrefillDataStateModel) { return state.masterCargoTypes; }
  @Selector() static availabilityTypes(state: PrefillDataStateModel) { return state.availabilityTypes; }
  @Selector() static terminalsForPort(state: PrefillDataStateModel) { return state.terminalsForPort; }
  @Selector() static allPorts(state: PrefillDataStateModel) { return state.allPorts; }
  @Selector() static allTerminals(state: PrefillDataStateModel) { return state.allTerminals; }
  @Selector() static allEmployers(state: PrefillDataStateModel) { return state.allEmployers; }
  @Selector() static allBerths(state: PrefillDataStateModel) { return state.allBerths; }
  @Selector() static allVessels(state: PrefillDataStateModel) { return state.allVessels; }
  @Selector() static allSuspensionTypes(state: PrefillDataStateModel) { return state.allSuspensionTypes; }
  @Selector() static allDebitTypes(state: PrefillDataStateModel) { return state.allDebitTypes; }
    
  @Selector() static filteredPorts(state: PrefillDataStateModel) { return state.filteredPorts; }
  @Selector() static filteredTerminals(state: PrefillDataStateModel) { return state.filteredTerminals; }
  @Selector() static filteredEmployers(state: PrefillDataStateModel) { return state.filteredEmployers; }
  @Selector() static filteredStates(state: PrefillDataStateModel) { return state.filteredStates; }
  @Selector() static filteredVessels(state: PrefillDataStateModel) { return state.filteredVessels; }
  @Selector() static filteredTimekeepers(state: PrefillDataStateModel) { return state.filteredTimekeepers; }
  
  @Selector() static tenantLoaded(state: PrefillDataStateModel) { return !!state.tenantId; }
  @Selector() static systemNotifications(state: PrefillDataStateModel) { return state.systemNotifications; }
  @Selector() static overrideCertifications(state: PrefillDataStateModel) { return state.overrideCertifications; }
  @Selector() static tenantId(state: PrefillDataStateModel) { return state.tenantId; }
  @Selector() static useWorkerQueue(state: PrefillDataStateModel) { return state.useWorkerQueue; }
  @Selector() static trainingHoursMaxLimitEnabled(state: PrefillDataStateModel) { return state.trainingHoursMaxLimitEnabled; }
  @Selector() static jobTypes(state: PrefillDataStateModel) { return state.jobTypes; }

  @Selector() static allUsers(state: PrefillDataStateModel) { return state.allUsers; }
  @Selector() static allUnions(state: PrefillDataStateModel) { return state.allUnions; }
  @Selector() static musterUnions(state: PrefillDataStateModel) { return state.musterUnions; }
  @Selector() static dashboardTabs(state: PrefillDataStateModel) { return state.dashboardTabs; }
  @Selector() static useMuster(state: PrefillDataStateModel) { return state.useMuster; }

  @Action(SetFilter)
  setFilter(ctx: StateContext<PrefillDataStateModel>, { items, filter }) {
    const itemsAsOptions = items.map(item => {
      return { text: item.value, value: item.key };
    });
    ctx.patchState({ [filter]: itemsAsOptions });
  }

  @Action(AddFilter)
  addFilter(ctx: StateContext<PrefillDataStateModel>, { filter, item }) {
    if (item) {
      const state = ctx.getState();
      const asOption = { text: item.value, value: item.key };

      if (!filterInList(state[filter], item.key)) {
        ctx.patchState({ [filter]: [...state[filter], asOption] });
      }
    }
  }

  @Action(RemoveFilter)
  removeFilter(ctx: StateContext<PrefillDataStateModel>, { filter, item }) {
    if (item) {
      const state = ctx.getState();

      if (filterInList(state[filter], item.key)) {
        const newFilters = state[filter].filter(f => {
          return f.value !== item.key;
        });
        ctx.patchState({ [filter]: newFilters });
      }
    }
  }

  @Action(LoadUnionsAsOptions)
  loadUnions(ctx: StateContext<PrefillDataStateModel>) {
    const loadedUnions = this.store.selectSnapshot(UnionsState.unions);

    const allAsOptions = loadedUnions.map(item => {
      return { text: `${item.unionCode} - ${item.unionDescription}`, value: item.id };
    });
    const musterUnions = [...loadedUnions].filter(e => e.useMuster);
    const musterAsOptions = musterUnions.map(item => {
      return { text: `${item.unionCode} - ${item.unionDescription}`, value: item.id };
    });

    ctx.patchState({
      allUnions: allAsOptions,
      musterUnions: musterAsOptions
    });
  }

  @Action(LoadTerminalsAsOptions)
  loadTerminals(ctx: StateContext<PrefillDataStateModel>) {
    const loadedTerminals = this.store.selectSnapshot(TerminalsState.terminals);

    const asOptions = loadedTerminals.map(item => {
      return { text: item.name, value: item.id };
    });

    ctx.patchState({
      allTerminals: asOptions
    });
  }

  @Action(LoadPortsAsOptions)
  loadPorts(ctx: StateContext<PrefillDataStateModel>) {
    const loadedPorts = this.store.selectSnapshot(PortsState.ports);

    const portsAsOptions = loadedPorts.map(port => {
      return { text: port.name, value: port.id };
    });

    ctx.patchState({
      allPorts: portsAsOptions
    });
  }

  @Action(GetTerminalsOptionsByPort)
  getTerminals(ctx: StateContext<PrefillDataStateModel>, { portId }) {
    const terminals: Terminal[] = this.store.selectSnapshot(TerminalsState.terminals);

    const found: Terminal[] = terminals.filter(terminal => {
      return terminal.port?.id === portId;
    });
    const options: SelectOption[] = found.map(res => {
      return { text: res.name, value: res.id };
    });

    ctx.patchState({
      terminalsForPort: options
    });
  }

  @Action(AddCityAsOption)
  addcity(ctx: StateContext<PrefillDataStateModel>, { city }) {
    if (city) {
      const state = ctx.getState();
      const asOption = { text: city, value: city };

      if (filterInList(state.cities, city)) {
        ctx.patchState({ cities: [...state.cities, asOption] });
      }
    }
  }

  @Action(LoadCitiesAsOptions)
  loadCities(ctx: StateContext<PrefillDataStateModel>, { cities }) {
    if (cities) {
      const asOptions = cities.map(city => {
        return { text: city.key, value: city.value };
      });
      ctx.patchState({ cities: asOptions });
    }
  }

  @Action(LoadSuspensionTypesAsOptions)
  loadSuspensionTypes(ctx: StateContext<PrefillDataStateModel>, { suspensionTypes }) {
    if (suspensionTypes) {
      ctx.patchState({
        allSuspensionTypes: suspensionTypes.map((suspensionType) => {
          return {
            text: suspensionType.name,
            value: suspensionType.id
          };
        })
      });
    }
  }

  @Action(LoadDebitTypesAsOptions)
  loadDebitTypes(ctx: StateContext<PrefillDataStateModel>, action: LoadDebitTypesAsOptions) {
    if (action.debitTypes) {
      ctx.patchState({
        allDebitTypes: action.debitTypes.map((debitType) => {
          return {
            text: debitType.name,
            value: debitType.id
          };
        })
      });
    }
  }

  @Action(SetStatesAsOptions)
  loadStates(ctx: StateContext<PrefillDataStateModel>, { states }) {
    if (states) {
      const asOptions = states.map(state => {
        return { text: state.value, value: state.key };
      });
      ctx.patchState({ filteredStates: asOptions });
    }
  }

  @Action(AddStateAsOption)
  addState(ctx: StateContext<PrefillDataStateModel>, { item }) {
    if (item) {
      const state = ctx.getState();

      if (filterInList(state.filteredStates, item.key)) {
        ctx.patchState({
          filteredStates: [
            ...state.filteredStates, 
            {
              text: item.value,
              value: item.key
            }
          ]
        });
      }
    }
  }

  @Action(LoadPrefillData)
  load(ctx: StateContext<PrefillDataStateModel>) {
    this.dropdownOptionsService.getPrefillData().subscribe(
      res => {
        const states = res.filter(data => data.name === "states")[0];
        const facilities = res.filter(data => data.name === "facilities")[0];
        const occCodes = res.filter(data => data.name === "occCodes")[0];
        const cargoDifferentials = res.filter(data => data.name === "cargoDifferentials")[0];
        const masterCargoTypes = res.filter(data => data.name === "masterCargoTypes")[0];
        ctx.patchState({
          states: states.items,
          facilities: facilities.items,
          occCodes: occCodes.items,
          cargoDifferentials: cargoDifferentials.items,
          statuses: this.dropdownOptionsService.getStatuses(),
          masterCargoTypes: masterCargoTypes.items,
          loaded: true
        });
      },
      error => {
        ctx.patchState({
          error: true,
          statuses: this.dropdownOptionsService.getStatuses()
        });
      }
    );
  }

  @Action(LoadEmployersAsOptions)
  loadEmployers(ctx: StateContext<PrefillDataStateModel>) {
    const loadedEmployers = this.store.selectSnapshot(EmployersState.employers);

    const asOptions = loadedEmployers.map(item => {
      return { text: item.name, value: item.id };
    });

    ctx.patchState({
      allEmployers: asOptions
    });
  }

  @Action(LoadDeptCodesAsOptions)
  loadDeptCodes(ctx: StateContext<PrefillDataStateModel>) {
    const loadedDeptCodes: Array<DeptCode> = this.store.selectSnapshot(DeptCodesState.codes);
    const asOptions: Array<SelectOption> = loadedDeptCodes.map((item) => {
      return { text: item.name, value: item.id };
    });
    ctx.patchState({ deptCodes: asOptions });
  }

  @Action(LoadBerthsAsOptions)
  loadBerths(ctx: StateContext<PrefillDataStateModel>) {
    const loadedBerths: Array<Berth> = this.store.selectSnapshot(BerthsState.berths);
    const asOptions: Array<SelectOption> = loadedBerths.map((item) => {
      return { text: item.name, value: item.id };
    });
    ctx.patchState({ allBerths: asOptions });
  }

  @Action(LoadVesselsAsOptions)
  loadVessels(ctx: StateContext<PrefillDataStateModel>) {
    return this.dropdownOptionsService.getVesels().pipe(
      tap(
        (response) => {
          const asOptions = response.map(item => {
            return { text: item.vesselName, value: item.id };
          });
      
          ctx.patchState({
            allVessels: asOptions
          });
        }
      )
    )
  }

  @Action(LoadAvailabilityTypesAsOptions)
  loadAvailabilityTypesAsOptions(ctx: StateContext<PrefillDataStateModel>) {
    const loadedAvailabilityTypes = this.store.selectSnapshot(AvailabilityState.availabilityTypes(true));

    const availabilityTypesAsOptions = loadedAvailabilityTypes.map(a => {
      return { text: a.name, value: a.id, type: a};
    });

    ctx.patchState({
      availabilityTypes: availabilityTypesAsOptions
    });
  }

  @Action(LoadJobTypesAsOptions)
  loadJobTypesAsOptions(ctx: StateContext<PrefillDataStateModel>) {
    const loadedJobTypes: SelectOption[] = ctx.getState().jobTypes;
    if (!loadedJobTypes?.length) {
      return this.dropdownOptionsService.getJobTypes().pipe(
        tap(
          (response) => {
            ctx.patchState({
              jobTypes: response[0].items
            });
          }
        )
      )
    }
  }

  @Action(LoadTenantId)
  loadTenantId(ctx: StateContext<PrefillDataStateModel>, action: LoadTenantId) {
    this.userPermissionsService.getTenantByDomain(action.name).subscribe(
      (response) => {
        ctx.patchState({
          tenantId: response.tenantId,
          displayDashboard: response.displayDashboard,
          systemNotifications: response.messages,
          overrideCertifications: response.overrideCertifications,
          useWorkerQueue: response.useWorkerQueue,
          trainingHoursMaxLimitEnabled: response.trainingHoursMaxLimitEnabled
        });
      },
      (error) => {
        ctx.patchState({ error: true });
      }
    );
  }

  @Action(LoadUseMuster)
  loadUseMuster(ctx: StateContext<PrefillDataStateModel>) {
    this.userPermissionsService.getUseMuster().subscribe(
      (response) => {
        const dashboardTabs: NavigationItem[] = response ? DASHBOARD_TABS : DASHBOARD_TABS.slice(0, 4);
        ctx.patchState({
          dashboardTabs: dashboardTabs,
          useMuster: response
        });
      }
    );
  }

  @Action(LoadUsersAsOptions)
  loadUsersAsOptions(ctx: StateContext<PrefillDataStateModel>) {
    this.userPermissionsService.getUsers(0, 1000, '').subscribe(
      (response) => {
        ctx.patchState({
          allUsers: response.items.map(e => { return {
            value: e.id,
            text: `${e.name} ${e.surname} (${e.userName})`,
          }})
        });
      }
    );
  }
}
