import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { LoadDeptCodesAsOptions } from "src/app/shared/states/prefill-data/prefill-data.actions";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults } from "../../admin.state";
import { DeptCodesService } from "../dept-codes.service";
import { AddCode, LoadCodes, RemoveCode, UpdateCode } from "./dept-codes.actions";
import { DeptCodesStateModel } from "./dept-codes.model";

@State<DeptCodesStateModel>({
  name: "deptCodes",
  defaults: AdminPagesStateDefaults
})
@Injectable()
export class DeptCodesState {
  
  constructor(
    private deptCodesService: DeptCodesService
  ) {}

  @Selector() static codes(state: DeptCodesStateModel) { return state.items; }
  @Selector() static loading(state: DeptCodesStateModel) { return state.loading; }
  @Selector() static saving(state: DeptCodesStateModel) { return state.saving; }

  @Action(LoadCodes)
  loadDeptCodes(ctx: StateContext<DeptCodesStateModel>) {
    ctx.patchState({
      items: [],
      loading: true
    });
    
    return this.deptCodesService.getDeptCodes().pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            loaded: true,
            loading: false
          });
          ctx.dispatch(
            new LoadDeptCodesAsOptions()
          );
        }
      )
    );
  }

  @Action(AddCode)
  addDeptCode(ctx: StateContext<DeptCodesStateModel>, action: AddCode) {
    ctx.patchState({ saving: true });
    
    return this.deptCodesService.create(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: [response, ...state.items],
            saving: false
          });
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(UpdateCode)
  updateDeptCode(ctx: StateContext<DeptCodesStateModel>, action: UpdateCode) {
    ctx.patchState({ saving: true });

    return this.deptCodesService.update(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: getUpdatedItems(response, state.items),
            saving: false
          });
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(RemoveCode)
  removeDeptCode(ctx: StateContext<DeptCodesStateModel>, action: RemoveCode) {
    return this.deptCodesService.remove(action.deptCodeId).pipe(
      tap(
        () => {
          const state = ctx.getState();
          ctx.patchState({
            items: state.items.filter(
              (item) => {
                return item.id !== action.deptCodeId;
              }
            )
          });
        }
      )
    );
  }
}
