import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { WorkerAvailability } from 'src/app/shared/interfaces/worker-availability.interface';
import { API_URL } from 'src/environments/settings';

@Injectable({ providedIn: 'root' })
export class AvailabilityService {

	private apiUrl: string;

	constructor(
		private http: HttpClient,
		private env: EnvironmentService
	) {
		this.apiUrl = this.env.getValueTransferState(API_URL);
	}

	getWeekAvailability(unionId: number, workerIds: number[], startDate: string) {
		let string = "?"
		workerIds.forEach((id, i) => string = string.concat(i == 0 ? `ids=${id}` : `&ids=${id}`));
		string += `&startDate=${startDate}`;
		
		return this.http.get<any>(`${this.apiUrl}/app/worker/workerAvailability/${unionId}${string}`);
	}

	saveAvailability(availability: WorkerAvailability) {
		return this.http.post(`${this.apiUrl}/app/worker/workerAvailability`, availability);
	}

	saveAvailabilityWithExpireDate(availability: WorkerAvailability) {
		return this.http.post(`${this.apiUrl}/app/worker/workerAvailabilityWithExpireDate`, availability);
	}

	getAvailabilityTypes() {
		const params: HttpParams = new HttpParams({
			fromObject: {
				includeWorking: true
			}
		});

		return this.http.get<any>(`${this.apiUrl}/app/worker/availabilityTypes`, { params });
	}
}
