import { DeptCode } from "../models/dept-code.model";

export class LoadCodes {
  public static readonly type = "[Dept Codes] load dept codes";
}

export class AddCode {
  public static readonly type = "[Dept Codes] add dept code";
  constructor(
    public payload: DeptCode,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateCode {
  public static readonly type = "[Dept Codes] update dept code";
  constructor(
    public payload: DeptCode,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveCode {
  public static readonly type = "[Dept Codes] remove dept code";
  constructor(
    public deptCodeId: string
  ) {}
}
