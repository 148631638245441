import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, createSelector } from "@ngxs/store";
import moment from "moment";
import { take, tap } from "rxjs/operators";
import { RosterService } from "src/app/features/roster/services/roster.service";
import { WorkerAvailability, WorkerAvailabilityGrouped } from "src/app/shared/interfaces/worker-availability.interface";
import { DashboardPagesStateDefaults, SetError } from "src/app/shared/states/dashboard.state";
import { LoadAvailabilityTypesAsOptions } from "src/app/shared/states/prefill-data/prefill-data.actions";
import { AvailabilityService } from "../services/availability.service";
import {
	GetAvailabilityTypes,
	GetWeekAvailability,
	ResetAvailability,
	SaveAvailabilityWorker,
	SaveWorkerAvailabilityWithExpireDate
} from "./availability.action";
import { AvailabilityStateModel } from "./availability.model";

@State<AvailabilityStateModel>({
	name: "availability",
	defaults: {
		...DashboardPagesStateDefaults,
		workersAvailability: [],
		availabilityTypes: [],
		totalWorkers: null,
		loadingTypes: false
	}
})
@Injectable()
export class AvailabilityState {
	constructor(
		private availabilityService: AvailabilityService,
		private rosterService: RosterService
	) {}

	@Selector() static workersAvailability(state: AvailabilityStateModel) { return state.workersAvailability; }
	@Selector() static totalWorkers(state: AvailabilityStateModel) { return state.totalWorkers; }
	@Selector() static loading(state: AvailabilityStateModel) { return state.loading; }
	@Selector() static loadingTypes(state: AvailabilityStateModel) { return state.loadingTypes; }
  static availabilityTypes(includeWorking: boolean) {
    return createSelector([AvailabilityState], (state: AvailabilityStateModel) => {
      if (!includeWorking) {
				return state.availabilityTypes.filter((e) => {
					return !['W', 'Q'].includes(e.code);
				});
			}
			return state.availabilityTypes;
    });
  }

	@Action(GetWeekAvailability, { cancelUncompleted: true })
	getWeekAvailability(ctx: StateContext<AvailabilityStateModel>, action: GetWeekAvailability) {
		ctx.patchState({ 
			workersAvailability: [],
			loading: true,
			totalWorkers: null
		});

		return this.rosterService.getWorker(action.searchQ, action.unionId, action.skipCount, action.maxResults, null, null, null, null, false, true).subscribe(
			(workers) => {
				ctx.patchState({
					totalWorkers: workers.totalCount
				});
				
				const workerIds: number[] = workers.items.map(w => w.id);
				return this.availabilityService.getWeekAvailability(action.unionId, workerIds, moment().format('YYYY-MM-DD')).pipe(
					take(1),
					tap(
						(response: WorkerAvailabilityGrouped[]) => {
							response.forEach(r => {
								r.worker = workers.items.find(w => r.workerId == w.id);
							});
							ctx.patchState({
								workersAvailability: response,
								loading: false
							});
						},
						(error) => {
							ctx.patchState({
								workersAvailability: [],
								loading: false,
								totalWorkers: null
							});
							ctx.dispatch(new SetError({ loading: true }));
						}
					)
				).subscribe();
			},
			(error) => {
				ctx.patchState({
					workersAvailability: [],
					loading: false
				});
				ctx.dispatch(new SetError({ loading: true }));
			}
		);
	}

	@Action(SaveAvailabilityWorker)
	saveAvailabilityWorker(ctx: StateContext<AvailabilityStateModel>, action: SaveAvailabilityWorker) {
		ctx.patchState({ saving: true });
		const state = ctx.getState();

		return this.availabilityService.saveAvailability(action.availability).pipe(
			tap(
				(response: WorkerAvailability[]) => {
					if (response.length > 0) {
						let foundWA = state.workersAvailability.find(wA => wA.workerId == response[0].workerId);

						const wA = state.workersAvailability.map((item: any) => {
							const itemAux = JSON.parse(
								JSON.stringify(item)
							);
							if(item.workerId == foundWA.workerId) {
								itemAux.availabilityList = response;
							}
							return itemAux
						})
						ctx.patchState({
							workersAvailability: wA,
							saving: false
						});
					}
					
					action.callback(null);
				},
				error => {
					action.callback(error);
					ctx.patchState({ saving: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(SaveWorkerAvailabilityWithExpireDate)
	SaveWorkerAvailabilityWithExpireDate(ctx: StateContext<AvailabilityStateModel>, action: SaveWorkerAvailabilityWithExpireDate) {
		ctx.patchState({ saving: true });
		const state = ctx.getState();

		return this.availabilityService.saveAvailabilityWithExpireDate(action.availability).pipe(
			tap(
				(res: WorkerAvailability[]) => {
					if (res.length > 0) {
						let foundWA = state.workersAvailability.find(wA => wA.workerId == res[0].workerId);

						const wA = state.workersAvailability.map((item: any) => {
							const itemAux = JSON.parse(
								JSON.stringify(item)
							);
							if(item.workerId == foundWA.workerId) {
								itemAux.availabilityList = res;
							}
							return itemAux
						})
						ctx.patchState({
							workersAvailability: wA,
							saving: false
						});
					}

					action.callback(null);
				},
				error => {
					action.callback(error);
					ctx.patchState({ saving: false });
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(GetAvailabilityTypes)
	getAvailabilityTypes(ctx: StateContext<AvailabilityStateModel>) {
		ctx.patchState({
			availabilityTypes: [],
			loadingTypes: true
		});

		return this.availabilityService.getAvailabilityTypes().pipe(
			tap(
				(response) => {
					ctx.patchState({
						availabilityTypes: response,
						loadingTypes: false
					});
					ctx.dispatch(new LoadAvailabilityTypesAsOptions());
				},
				(error) => {
					ctx.patchState({
						availabilityTypes: [],
						loadingTypes: false
					});
					ctx.dispatch(new SetError({ loading: true }));
				}
			)
		);
	}

	@Action(ResetAvailability)
	resetAvailability(ctx: StateContext<AvailabilityStateModel>) {
		ctx.patchState({ workersAvailability: [] });
	}
}
