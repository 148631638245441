import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { Port } from "./models/port.model";

interface FilterPorts {
  state?: string;
  city?: string;
  searchQ?: string;
  isActive?: boolean;
}

@Injectable({ providedIn: "root" })
export class PortsService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = this.env.getValueTransferState(API_URL);
  }

  getPorts(filters?: FilterPorts) {
    if (this.env.isBrowser) {
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          if (filters[key] === '') {
            delete filters[key];
          }
        }
      }

      const params: HttpParams = new HttpParams({
        fromObject: {
          ...filters,
          MaxResultCount: 50
        }
      });
      return this.http.get<any>(`${this.apiUrl}/app/port/withFilters`, { params });
    }
  }

  updatePort(port: Port) {
    return this.http.put<any>(`${this.apiUrl}/app/port/${port.id}`, port);
  }

  createPort(port: Port) {
    return this.http.post<any>(`${this.apiUrl}/app/port`, port);
  }

  removePort(portId: string) {
    return this.http.delete(`${this.apiUrl}/app/port/${portId}`);
  }
}
