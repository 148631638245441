import { Injectable } from "@angular/core";
import { Action, Actions, Selector, State, StateContext, ofAction } from "@ngxs/store";
import { takeUntil, tap } from "rxjs/operators";
import { FilterAction } from "../../../../shared/components/filters/filters.component";
import { AddCityAsOption, AddStateAsOption, LoadCitiesAsOptions, LoadPortsAsOptions, SetStatesAsOptions } from "../../../../shared/states/prefill-data/prefill-data.actions";
import { getFilterData, getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults, SetError } from "../../admin.state";
import { PortsService } from "../ports.service";
import { AddPort, LoadPorts, RemovePort, UpdatePort } from "./ports.actions";
import { PortsStateModel } from "./ports.model";

@State<PortsStateModel>({
  name: "ports",
  defaults: AdminPagesStateDefaults
})
@Injectable()
export class PortsState {
  constructor(private portsService: PortsService, private actions$: Actions) {}

  isFiltered: boolean;

  @Selector() static ports(state: PortsStateModel) { return state.items; }
  @Selector() static loading(state: PortsStateModel) { return state.loading; }
  @Selector() static saving(state: PortsStateModel) { return state.saving; }

  @Action(LoadPorts)
  loadPorts(
    { patchState, getState, dispatch }: StateContext<PortsStateModel>,
    { filters }
  ) {
    patchState({ items: [], loading: true });

    return this.portsService.getPorts(filters?.items || {}).pipe(
      tap(
        res => {
          this.isFiltered = filters?.hasEnabledFilters;

          patchState({
            items: res.items,
            loaded: true,
            loading: false
          });

          //Refresh the city and state option if there are no filters enabled
          if (!filters?.hasEnabledFilters) {
            const citiesArray = getFilterData(res, "AvailableCities");
            dispatch(new LoadCitiesAsOptions(citiesArray));

            const availableStates = getFilterData(res, "AvailableStates");
            dispatch(new SetStatesAsOptions(availableStates));
          }

          dispatch(new LoadPortsAsOptions());
        },
        err => {
          patchState({ loading: false, items: [] });
          dispatch(new SetError({ loading: err }));
        }
      ),
      takeUntil(this.actions$.pipe(ofAction(FilterAction)))
    );
  }

  @Action(AddPort)
  addPort(ctx: StateContext<PortsStateModel>, action: AddPort) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });

    return this.portsService.createPort(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          if (this.isFiltered) {
            ctx.dispatch(new LoadPorts()).subscribe((res: any) => {
              let ports = [...res.ports.items];
              ports.pop();
  
              ctx.patchState({
                items: [response, ...ports],
                saving: false
              });
            });
          } else {
            ctx.patchState({
              items: [response, ...state.items],
              saving: false
            });
            ctx.dispatch([
              new AddCityAsOption(response.city),
              new AddStateAsOption({
                key: response.state.id,
                value: response.state.stateName
              })
            ]);
          }
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ loading: false, saving: false });
        }
      )
    );
  }

  @Action(UpdatePort)
  updatePort(ctx: StateContext<PortsStateModel>, action: UpdatePort) {
    const state = ctx.getState();

    ctx.patchState({ saving: true });

    return this.portsService.updatePort(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          ctx.patchState({
            items: getUpdatedItems(response, state.items),
            saving: false
          });
          ctx.dispatch(
            new AddStateAsOption({
              key: response.state.id,
              value: response.state.stateName
            })
          );
        },
        (error) => {
          action.callbackError();
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(RemovePort)
  removePort(ctx: StateContext<PortsStateModel>, action: RemovePort) {
    const state = ctx.getState();

    return this.portsService.removePort(action.portId).pipe(
      tap(
        () => {
          action.callbackSuccess();
          const updatedPorts = state.items.filter(item => {
            return item.id !== action.portId;
          });
          ctx.patchState({ items: updatedPorts });
        },
        (error) => {
          action.callbackError();
        }
      )
    );
  }
}
